import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Pagination, Skeleton } from "antd";
import Search from "antd/es/input/Search";
import { useParams, useSearchParams } from "react-router-dom";

import useFloorsByPropertyCount from "../../../../../api/hooks/useFloorsByPropertyCount";
import useFloorsByPropertyId from "../../../../../api/hooks/useFloorsByPropertyId";
import useAuth from "../../../../../hooks/useAuth";
import useWindowSize from "../../../../../hooks/useWindowSize";
import BackButton from "../../../../Shared/BackButton";
import FloorCard from "./FloorCard";

function FloorList() {
  const window = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { auth } = useAuth();
  const { propertyId } = useParams();
  const pageSize = auth?.rowsPerPage;
  // get search params from URl and format them as expected from the API
  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;

  const { data: totalFloors } = useFloorsByPropertyCount({
    propertyId,
    search: debouncedSearchQuery,
  });

  const {
    data: property,
    status,
    refetch: getFloorsByProperty,
  } = useFloorsByPropertyId({
    propertyId,
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    search: debouncedSearchQuery,
  });

  useEffect(() => {
    getFloorsByProperty();
  }, [debouncedSearchQuery, page, getFloorsByProperty]);

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="content-wrapper overflow-y-auto">
      <BackButton
        redirect={{
          to: "/preferences/property-management/floor-management/properties",
        }}
        confirmation={false}
        className="self-start uppercase tracking-widest mr-4"
      />
      <div className="flex flex-col-reverse sm:flex-row sm:items-center mb-4 md:mb-2 w-full">
        <div className="mt-6 sm:mt-0 flex-1 flex flex-col md:flex-row md:items-center mr-auto ">
          <h1 className="page-title mb-0">{property?.Name}</h1>
        </div>
        <Search
          className="w-full md:w-fit mr-4"
          placeholder="Search Floors"
          onSearch={onSearch}
          onChange={handleSearchChange}
          allowClear
          size="middle"
        />
      </div>
      {status === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {status === "success" &&
        (property?.Floors?.length === 0 || !property?.Floors) && (
          <div className="p-6 text-white text-opacity-80">
            <FrownOutlined className="text-xl mr-2" />
            We couldn`t find any matches
          </div>
        )}

      {status === "success" && property?.Floors?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
          {property?.Floors?.sort(
            (firstElement, secondElement) => firstElement.Id - secondElement.Id,
          ).map((floor) => (
            <div className="sm:my-2 my-4" key={floor.Id}>
              <FloorCard
                key={`${floor.Id}_${floor.Name}`}
                objectFloor={floor}
                refetchPropertyFloors={getFloorsByProperty}
              />
            </div>
          ))}
        </div>
      )}

      {status === "success" &&
        property?.Floors?.length > 0 &&
        totalFloors > pageSize && (
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            total={totalFloors}
            current={Number(searchParams.get("page")) || 1}
            showSizeChanger={false}
            size={window?.width <= 576 && "small"}
            onChange={(page) => {
              setSearchParams({ page });
            }}
          />
        )}
    </div>
  );
}

export default FloorList;
