import { Col, Form, Input, Row, Select } from "antd";
import Search from "antd/es/transfer/search";
import PropTypes from "prop-types";

import useWindowSize from "../../../../../hooks/useWindowSize";
import { deviceTypeOptions } from "../helperFuncs/helperFunctions";

function NameFilterGroup({ setDeviceTypeId, handleSearchChange }) {
  const window = useWindowSize();
  if (window.width > 1024) {
    return (
      <Row>
        <Col xl={12} md={12} className="pr-24">
          <Form.Item
            className="mb-4 mr-2 w-full"
            label="Name"
            name="Name"
            rules={[{ required: true, message: "Name field is required." }]}
          >
            <Input size="middle" maxLength={64} />
          </Form.Item>
        </Col>
        <Col
          xl={12}
          sm={24}
          className="inline-flex items-center justify-center mt-4 pl-24"
        >
          <Select
            className="w-full mr-2"
            placeholder="Device Type"
            allowClear
            options={deviceTypeOptions}
            onChange={(value) => {
              setDeviceTypeId(value);
            }}
          />
          <Search
            className="w-full"
            placeholder="Search Systems"
            onChange={handleSearchChange}
            allowClear
            size="middle"
          />
        </Col>
      </Row>
    );
  }
  if (window.width <= 1024) {
    return (
      <>
        <Form.Item
          className="mb-4"
          label="Name"
          name="Name"
          rules={[{ required: true, message: "Name field is required." }]}
        >
          <Input size="middle" maxLength={64} />
        </Form.Item>
        <div className="mb-4">
          <Select
            className="w-full mb-4"
            placeholder="Device Type"
            allowClear
            options={deviceTypeOptions}
            onChange={(value) => {
              setDeviceTypeId(value);
            }}
          />
          <Search
            className="w-full mr-2"
            placeholder="Search Systems"
            onChange={handleSearchChange}
            allowClear
            size="middle"
          />
        </div>
      </>
    );
  }
}

NameFilterGroup.propTypes = {
  setDeviceTypeId: PropTypes.func,
  handleSearchChange: PropTypes.func,
};
NameFilterGroup.defaultProps = {
  setDeviceTypeId: () => {},
  handleSearchChange: () => {},
};

export default NameFilterGroup;
